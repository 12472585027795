import React from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import Login from "../components/login"
import PrivateRoute from "../components/privateRoute"
import PortalHome from "../components/portal-home"
import PortalContact from "../components/portal-contact"
import PortalAppliances from "../components/portal-appliances"
import PortalPlans from "../components/portal-plans"
import PortalColors from "../components/portal-colors"
import PortalLocation from "../components/portal-location"
import PortalGallery from "../components/portal-gallery"
import PortalSpecification from "../components/portal-specification"
import PortalDeveloper from "../components/portal-developer";
import PortalBrochure from "../components/portal-brochure";

const Portal = ({ data }) => (
  <Router>
    <Login path="/portal/login" data={data} />
    <PrivateRoute
      path={"/portal/appliances"}
      component={PortalAppliances}
      data={data}
    />
    <PrivateRoute
      path={"/portal/specification"}
      component={PortalSpecification}
      data={data}
    />
    <PrivateRoute
      path={"/portal/palette"}
      component={PortalColors}
      data={data}
    />
      <PrivateRoute
          path={"/portal/brochure"}
          component={PortalBrochure}
          data={data}
      />
    <PrivateRoute
      path={"/portal/plans"}
      component={PortalPlans}
      data={data}
    />
    <PrivateRoute
      path={"/portal/gallery"}
      component={PortalGallery}
      data={data}
    />
    <PrivateRoute
      path={"/portal/developer"}
      component={PortalDeveloper}
      data={data}
    />
    <PrivateRoute
      path={"/portal/location"}
      component={PortalLocation}
      data={data}
    />
    <PrivateRoute
      path={"/portal/contact"}
      component={PortalContact}
      data={data}
    />
    <PrivateRoute path={"/portal"} default component={PortalHome} data={data} />
  </Router>
)

export default Portal

export const query = graphql`
  query PortalPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
