import React from "react"
import LayoutApp from "./layout-app"
import ContactForm from "./contact-form"

const PortalContact = ({ data }) => (
  <LayoutApp title={"Contact"}>
    <p className="mt-2 text-base leading-5 text-gray-600">
      If you have any queries regarding your new home fill in the form below
      and someone from the {data.site.siteMetadata.title} team will get back to
      you ASAP.
    </p>
    <ContactForm siteName={data.site.siteMetadata.title} />
  </LayoutApp>
)

export default PortalContact
