import React from "react"
import Layout from "./layout"
import Nav from "./nav"

const LayoutApp = ({ children, title }) => {
  return (
    <Layout>
      <Nav />
      <main className="py-10 container mx-auto py-10 px-4">
        <header>
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {title}
          </h1>
        </header>
        <main>{children}</main>
      </main>
    </Layout>
  )
}

export default LayoutApp
