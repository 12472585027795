import React from "react"
import logo from "../images/logo-horizontal.png"

import { Link, navigate } from "gatsby"
import { isLoggedIn, logout } from "../services/auth"
import { portalNavLinks } from "../services/nav"

const Nav = () => {
  const [navbarOpen, setNavbarOpen] = React.useState(false)

  return (
    <nav className="bg-white shadow-sm">
      <div className="container mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex flex-grow">
            <div className="flex-shrink-0 flex items-center">
              <Link to={"/portal"}>
                <img className="h-8 w-auto" src={logo} alt="logo" />
              </Link>
            </div>
            <div className="hidden md:justify-evenly md:flex-grow md:flex">
              {portalNavLinks.map(({ path, title, isExternal }, index) => {
                return (
                  isExternal
                    ? <a href={path} className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                      target="_blank">{title}</a>
                    : <Link
                        key={index}
                        to={path}
                        className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out"
                        activeClassName={"border-primary-500 text-gray-900 focus:border-primary-700"}
                      >
                        {title}
                      </Link>
                )
              })}
            </div>
          </div>

          <div className="hidden md:flex md:items-center">
            {isLoggedIn() && (
              <a
                href="/"
                onClick={event => {
                  event.preventDefault()
                  logout(() => navigate(`/portal/login`))
                }}
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              >
                Logout
              </a>
            )}
          </div>
          <div className="-mr-2 flex items-center md:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                className={`h-6 w-6 ${navbarOpen ? "hidden" : "block"}`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`h-6 w-6 ${navbarOpen ? "block" : "hidden"}`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`${navbarOpen ? "block" : "hidden"} md:hidden`}>
        <div className="pt-2 pb-3 space-y-1">
          {portalNavLinks.map(({ path, title, isExternal }, index) => {
            return (
              isExternal
                ? <a href={path} className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:outline-none focus:text-gray-800 focus:bg-gray-100 focus:border-gray-400 transition duration-150 ease-in-out" target="_blank">{title}</a>
                : <Link
                  key={index}
                  to={path}
                  className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:outline-none focus:text-gray-800 focus:bg-gray-100 focus:border-gray-400 transition duration-150 ease-in-out"
                  activeClassName={
                    "border-primary-500 text-primary-700 bg-primary-50 focus:text-primary-800 focus:bg-primary-100 focus:border-primary-700"
                  }
                >{title}</Link>
            )
          })}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div
            className="mt-3 space-y-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {isLoggedIn() && (
              <a
                href="/"
                onClick={event => {
                  event.preventDefault()
                  logout(() => navigate(`/portal/login`))
                }}
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem"
              >
                Logout
              </a>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
