import React, {useState} from "react"
import LayoutApp from "./layout-app"
import {graphql, useStaticQuery} from "gatsby"
import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

const PortalGallery = ({data}) => {
    const MARKETING = 'marketing'
    const PROGRESS = 'progress'
    const EARLYSTAGES = 'earlystages'
    const CGICONCEPTS = 'cgiconcepts'

    const [activeTab, setActiveTab] = useState(MARKETING)

    const handleTabChange = value => {
        setActiveTab(value)
    }

    let images = useStaticQuery(graphql`
    query {
        marketing: allFile(
            filter: {
              relativeDirectory: { eq: "gallery/marketing" }
            }
          ) {
            edges {
              node {
                base
                childImageSharp {
                  thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                  }
                  full: fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          progress: allFile(
            filter: {
              relativeDirectory: { eq: "gallery/progress" }
            }
          ) {
            edges {
              node {
                base
                childImageSharp {
                  thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                  }
                  full: fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          
          earlystages: allFile(
            filter: {
              relativeDirectory: { eq: "gallery/earlystages" }
            }
          ) {
            edges {
              node {
                base
                childImageSharp {
                  thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                  }
                  full: fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
             cgiconcepts: allFile(
            filter: {
              relativeDirectory: { eq: "gallery/cgiconcepts" }
            }
          ) {
            edges {
              node {
                base
                childImageSharp {
                  thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                  }
                  full: fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
 }   
  `)

    const marketingImages = images.marketing.edges.map(({node}) => node.childImageSharp)
    const progressImages = images.progress.edges.map(({node}) => node.childImageSharp)
    const earlystagesImages = images.earlystages.edges.map(({node}) => node.childImageSharp)
    const cgiconceptsImages = images.cgiconcepts.edges.map(({node}) => node.childImageSharp)

    return (
        <LayoutApp title={"Gallery"}>
            <p className="mt-2 text-base leading-5 text-gray-600">
                See Bright Heights develop from concept to construction, view the amazing images of the show flat and development of the building that the 
Dapatchi team worked diligently on to deliver the elegant top spec finish it has today.
            </p>

            <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
                <div className="px-4 py-5 sm:p-6">
                    <div className="sm:hidden">
                        <select
                            aria-label="Selected tab"
                            className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                            value={activeTab}
                            onChange={event => handleTabChange(event.target.value)}
                        >
                            <option value={MARKETING}>
                                Show Flat
                            </option>
                            <option value={PROGRESS}>
                                In Progress
                            </option>
                            <option value={EARLYSTAGES}>
                                Early Stages
                            </option>
                            <option value={CGICONCEPTS}>
                                CGI Concepts
                            </option>
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex">
                                <a
                                    onClick={() => handleTabChange(MARKETING)}
                                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer ${
                                        activeTab === MARKETING
                                            ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                                            : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                                    }`}
                                >
                                    Show Flat
                                </a>

                                <a
                                    onClick={() => handleTabChange(PROGRESS)}
                                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer ml-8 ${
                                        activeTab === PROGRESS
                                            ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                                            : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                                    }`}
                                >
                                    In Progress
                                </a>
                                
                                <a
                                    onClick={() => handleTabChange(EARLYSTAGES)}
                                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer ml-8 ${
                                        activeTab === EARLYSTAGES
                                            ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                                            : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                                    }`}
                                >
                                    Early Stages
                                </a>                       
                                     
                                <a
                                    onClick={() => handleTabChange(CGICONCEPTS)}
                                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer ml-8 ${
                                        activeTab === CGICONCEPTS
                                            ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                                            : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                                    }`}
                                >
                                    CGI Concepts
                                </a>              
                                
                                
                            </nav>
                        </div>
                    </div>

                    <div className={"mt-4 w-full"}>
                        <div className="divide-y divide-gray-200">
                            { activeTab === PROGRESS && <Gallery images={progressImages} /> }
                            { activeTab === MARKETING && <Gallery images={marketingImages} /> }
                            { activeTab === EARLYSTAGES && <Gallery images={earlystagesImages} /> }
                            { activeTab === CGICONCEPTS && <Gallery images={cgiconceptsImages} /> }
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </LayoutApp>
    )
}

export default PortalGallery
