export const colors = [
  {
    where: 'Walls',
    hex: '#fff',
    brand: 'Macpherson Trade Paints, Eclipse Emulsion',
    name: 'Brilliant White',
    url: 'https://www.macphersontrade.co.uk/product/interior-products/walls-and-ceilings/eclipse-emulsion/',
  },
  {
    where: 'Woodwork',
    hex: '#fff',
    brand: 'Crown Trade, Fastflow, Quick dry satin',
    name: 'White',
    url: 'https://www.crowntrade.co.uk/product/interior-products/metal-pvcu/crown-trade-fastflow-quick-dry-satin/',
  },
  {
    where: 'Handrails',
    hex: '#171717',
    brand: "Johnstone's Trade, Quick Dry Matt Blackboard Paint",
    name: 'Black',
    url: 'https://www.johnstonestrade.com/product/quick-dry-matt-blackboard-paint',
  }
];