import tap from './tap.jpg'

export const spec = {
  bathroom: {
    title: 'Bathroom',
    items: [
      {
        name: "Basin 1 (470mm x 550mm)",
        title: 'Duravit Vero Semi-recessed washbasin',
        description: 'Duravit Vero Semi-recessed washbasin with overflow, tap platform, fixings for installation, 550 mm.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/vero.com-en.html?product=115015',
        image: require('./vero-semi-recessed-basin.jpg'),
        features: ['design by Duravit', '470mm x 550mm', 'white']
      },
      {
        name: "Tap 1 for Basin 1 (135mm projection)",
        title: 'Dornbracht Single-lever basin mixer with pop-up waste - matt black',
        description: 'One-handle mixer for washbasin with one faucet mounting hole, with pivot rod for the drain set.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-33502660-33-Single-lever-basin-mixer-with-pop-up-waste-matt-black-matt-black',
        image: require('./dornbracht-basin-tap.jpg'),
        features: ['135mm projection', 'fixed spout', 'circular, air-enriched flow']
      },
      {
        name: "Basin 2 (350mm x 450mm)",
        title: 'Duravit Vero Handrinse basin ground',
        description: 'Duravit Vero Handrinse basin ground with overflow, with tap platform, fixings included, 450 mm.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/vero.com-en.html?product=94250',
        image: require('./vero-handrinse.jpg'),
        features: ['design by Duravit', '450mm', 'white']
      },
      {
        name: "Basin 3 (250mm x 500mm)",
        title: 'Duravit Vero Handrinse basin, furniture handrinse basin',
        description: 'Duravit Vero Handrinse basin, furniture handrinse basin with overflow, with tap platform, underneath glazed, 500 mm.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/vero.com-en.html?product=94259',
        image: require('./vero-furniture-basin.jpg'),
        features: ['design by Duravit', '500mm', 'white']
      },
      {
        name: "Tap 2 for Basin 2 & 3 (125mm projection)",
        title: 'Dornbracht Single-lever basin mixer with pop-up waste - matt black',
        description: 'One-handle mixer for washbasin with one faucet mounting hole, with pivot rod for the drain set.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-33502660-33-Single-lever-basin-mixer-with-pop-up-waste-matt-black-matt-black',
        image: require('./dornbracht-basin-tap.jpg'),
        features: ['135mm projection', 'fixed spout', 'circular, air-enriched flow']
      },
      {
        name: "Siphon for Basin 3",
        title: 'Dornbracht Siphon for basin - matt black',
        description: 'Siphon for Duravit Vero Handrinse basin, furniture handrinse basin (Basin 3).',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/US/Dornbracht-10060970-33-Siphon-for-basin---matt-black-matt-black',
        image: require('./basin-siphon.jpg'),
        features: ['rosette Ø 70 mm']
      },
      {
        name: "WC",
        title: 'ME by Starck Toilet wall mounted',
        description: 'Washdown model, Durafix included, UWL class 1, 4,5 litre flush.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/me_by_starck.com-en.html?product=2324615',
        image: require('./ME-toilet.jpg'),
        features: ['design by Philippe Starck', '370mm x 570mm', 'white']
      },
      {
        name: "Flush plate",
        title: 'Geberit kappa 50 flush plate.',
        description: '',
        itemUrl: '',
        image: require('./kapp50.jpg'),
        features: ['', '']
      },
      {
        name: "Bidet",
        title: 'ME by Starck Bidet wall mounted',
        description: 'With overflow, with tap platform, Durafix included.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/me_by_starck.com-en.html?product=2346756',
        image: require('./ME-bidet.jpg'),
        features: ['design by Philippe Starck', '370mm x 570mm', 'white']
      },
      {
        name: "Bidet mixer",
        title: 'Single-lever bidet mixer with pop-up waste - matt black',
        description: '',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/tr-TR/TR/Dornbracht-33600660-33-Single-lever-bidet-mixer-with-pop-up-waste---matt-black-matt-black',
        image: require('./bidet-tap.jpg'),
        features: ['125mm projection', 'circular, air-enriched flow', 'ball-joint pivotable through 15°']      },
      {
        name: "Bath",
        title: 'D-Code Bathtub',
        description: 'Rectangle, with one backrest slope, outlet in foot area, sanitary acrylic.',
        itemUrl: 'https://www.duravit.co.uk/products/all_series/d-code.com-en.html?product=3169326',
        image: require('./bath.jpg'),
        features: []
      },
      {
        name: "Bath thermostat and control",
        title: 'Dornbracht concealed thermostat with two function volume control - matt black',
        description: 'Rotary diverter with volume control and shut-off function.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-36426970-33-Concealed-thermostat-with-two-function-volume-control---matt-black-matt-black',
        image: require('./shower-stat.jpg'),
        features: ['cover plate Ø 150 mm', 'safety limit stop at 38°C', 'WRAS: 1906098']
      },
      {
        name: "Bath overflow",
        title: 'Dornbracht bath spout with pop-up waste and overflow - matt black',
        description: '',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-36247970-33-bath-spout-with-pop-up-waste-and-overflow---matt-black-matt-black',
        image: require('./bath-waste.jpg'),
        features: ['drain Ø 52mm', 'max. flow 27.8 l/min at 3 bar flow pressure']
      },
      {
        name: "Bath screen",
        title: 'Majestic Showers Genoa',
        description: 'This single panel bath screen represents absolute simplicity and pure functionality. Its minimalist design blends seamlessly with any bathroom style. Fitted with a dual action hinge, bringing the best of both worlds together, it glides smoothly both inwards and outwards.',
        itemUrl: 'https://www.majesticshowers.com/product/genoa/',
        image: require('./genoa-frameless-bath-screen.jpg'),
        features: ['frameless', 'clear glass', 'black components']
      },
      {
        name: "Shower Screen 1 - shower doors in recess",
        title: 'Majestic Showers Rio',
        description: 'Frameless door ideal for any recess',
        itemUrl: 'https://www.majesticshowers.com/product/rio/',
        image: require('./shower-screen.jpg'),
        features: ['frameless', 'clear glass', 'black components']
      },
      {
        name: "Shower Screen 2 - shower doors in a corner",
        title: 'Majestic Showers Portofino',
        description: 'A popular choice and versatile corner enclosure.',
        itemUrl: 'https://www.majesticshowers.com/product/portofino/',
        image: require('./shower-screen-2.jpg'),
      },
      {
        name: "Shower Screen 3 - shower doors in square corner",
        title: 'Majestic Showers Cadiz',
        description: 'Cadiz represents timeless simplicity. It completes your bathroom by enhancing even the most economic of spaces, achieving a pleasingly minimal finish.',
        itemUrl: 'https://www.majesticshowers.com/product/cadiz/',
        image: require('./shower-screen-3.jpg'),
      },
      {
        name: 'Rain shower',
        title: 'Dornbracht Rain shower with wall fixing - matt black',
        description: 'Rain shower, 450mm projection with anti-scale system.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-28649970-33-Rain-shower-with-wall-fixing---matt-black-matt-black',
        image: require('./rain-shower.jpg'),
        features: []
      },
      {
        name: 'Shower thermostat with three function control',
        title: 'Dornbracht concealed thermostat with three function volume control - matt black',
        description: 'Rotary diverter with volume control and shut-off function.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-36427970-33-Concealed-thermostat-with-three-function-volume-control---matt-black-matt-black\n',
        image: require('./3-function-shower.jpg'),
        features: []
      },
    ]
  },
  kitchen: {
    title: 'Kitchen',
    items: [
      {
        name: 'Howdens cabinets',
        title: 'Hockley Super Matt',
        description: 'Option A: “Grey” colour bottom cupboards/“white” upper cupboards. Option B: “Graphite” colour bottom cupboards/“white” upper cupboards.',
        itemUrl: 'https://www.howdens.com/kitchens/fitted-kitchens/hockley-super-matt/',
        image: require('./kitchen-unit.jpg'),
      },
      {
        name: 'Worktops',
        title: 'White Quartz',
        description: '',
        itemUrl: '',
        image: '',
      },
      {
        name: 'Backsplash',
        title: 'White Glass',
        description: '',
        itemUrl: '',
        image: '',
      },
      {
        name: 'Tap',
        title: 'Dombracht Profi single-lever mixer - polished chrome',
        description: '200mm projection. Flexible silicone hose with large operating range of up to 350 mm. Pivotable 360°.',
        itemUrl: 'https://professional.dornbrachtgroup.com/products/en-GB/GB/Dornbracht-33865895-00-Profi-single-lever-mixer---polished-chrome-polished-chrome',
        image: require('./kitchen-tap.jpg'),
      },
    ],
  },
  laminate: {
    title: 'Laminate Flooring',
    items: [
      {
        name: 'Laminate Flooring',
        title: 'Natural Engineered Oak Flooring',
        description: 'Natural Engineered Oak Flooring (non-visible or coffee colour), 10/3x150x1200)',
        itemUrl: 'https://www.woodandbeyond.com',
        image: require('./elp402.jpg'),
      },
      // {
      //   name: 'Laminate Flooring',
      //   title: '"Pergo Long" range - ELPE 410',
      //   description: 'Engineered laminate planks designed to provide clean visual lines and an endless looking floor. Features the innovative, easy-to-install PerfectFold™ 3.0 click system and TitanX™ surface protection.',
      //   itemUrl: 'https://domusgroup.com/products/pergo-long',
      //   image: require('./elp410.jpg'),
      // },
    ]
  },
  ironmongery: {
    title: 'Ironmongery',
    items: [
      {
        name: 'Entrance Door - Lever handle',
        title: 'Bisschop, Bauhaus Weimar ref 1920 FH/8004 FH (euro cylinder), Chrome plated polished',
        description: 'Pair of lever handles with 8mm spindle. Product code 900.93.483. Product characteristics: distance B: 78 mm, door thickness: 36–52 mm. Long PC backplate.',
        itemUrl: 'https://www.hafele.com.de/en/product/lever-handle-set-brass-bisschop-bauhaus-weimar-1920-8004/90093483',
        image: require('./handle-fireproof-door.jpg'),
      },
      {
        name: 'Entrance Door - Hinges',
        title: 'Pair 102x76x3mm Polished Stainless Steel Thrust Bearing Hinges',
        description: 'Adjutable closing speed - Adjustable latch speed - Rut lock - Power size EN3 - Fire Tested up to 120 mins - 10 year guarantee.',
        itemUrl: 'https://ironmongeryexperts.co.uk/fr14930-pss-pair-102x76x3mm-polished-stainless-steel-thrust-bear.html',
        image: require('./fireproof-hinges.jpg'),
      },
      {
        name: 'Entrance Door - Closer',
        title: 'Rutland Polished Nickel Contract Overhead Door Closer | TS.3204 PNP',
        description: '',
        itemUrl: 'https://ironmongeryexperts.co.uk/rutland-polished-nickel-contract-overhead-door-closer-ts-3204-pnp.html',
        image: require('./fireproof-door-closer.png'),
      },
      {
        name: 'Bed/Utility/Wardrobe - Lever handle',
        title: 'Set Matte Black Knurled Lever Handle on Rose | B1910BL',
        description: 'B Series Matte Black lever - Solid Grade 304 Stainless Steel - Sprung lever - Black accessories to suit.',
        itemUrl: 'https://ironmongeryexperts.co.uk/set-matte-black-knurled-lever-handle-on-rose-b1910bl.html?gclid=EAIaIQobChMI5Ksx9785wIV0NDeCh0r7gG6EAkYCCABEgL3EPDBwE',
        image: require('./doorhandle.jpeg'),
      },
      {
        name: 'Bed/Utility/Wardrobe - Latch',
        title: '76mm (3") Matte Black Tubular Mortice Latch | L0157BL',
        description: 'Suitable for commerical & residential use - Bolt through fixing holes to suit lever on rose - Dust socket included - Complete with fixing screws - Suitable for all sprung levers - Fire Rated FD30/60.',
        itemUrl: 'https://ironmongeryexperts.co.uk/76mm-3-matte-black-tubular-mortice-latch-l0157bl.html',
        image: require('./doorlatch.jpg'),
      },
      {
        name: 'Bed/Utility/Wardrobe - Hinges',
        title: 'Pair Powder Coat Black 102mm (4") Ball Bearing Steel Hinge | ZHS43PCB',
        description: 'Powder coated black - BS EN 1634-1 - Fire Rated 30/60 - Manufactured by Zoo Hardware.',
        itemUrl: 'https://ironmongeryexperts.co.uk/pair-powder-coat-black-102mm-4-ball-bearing-steel-hinge-zhs43pcb.html',
        image: require('./doorhinge.jpg'),
      },
      {
        name: 'WC/Shower/Bathroom - Lever Handle',
        title: 'Set Matte Black Knurled Lever Handle on Rose | B1910BL',
        description: 'B Series Matte Black lever - Solid Grade 304 Stainless Steel - Sprung lever - Black accessories to suit.',
        itemUrl: 'https://ironmongeryexperts.co.uk/set-matte-black-knurled-lever-handle-on-rose-b1910bl.html?gclid=EAIaIQobChMI5Ksx9785wIV0NDeCh0r7gG6EAkYCCABEgL3EPDBwE',
        image: require('./bathdoorhandle.jpeg'),
      },
      {
        name: 'WC/Shower/Bathroom - Lock',
        title: '76mm Contract Powder Coated Black Mortice Bathroom Lock',
        description: 'CE1121 - BSEN 1634-1 - Squared forend - Manufactured by Zoo Hardware.',
        itemUrl: 'https://ironmongeryexperts.co.uk/zbc76pcb-76mm-contract-powder-coated-black-bathroom-sashlock.html',
        image: require('./bathdoorlock.jpg'),
      },
      {
        name: 'WC/Shower/Bathroom - Turn/Release',
        title: 'Set Matte Black Bathroom Turn & Emergency Release | A9010BL',
        description: 'To suit A & B series matte black lever - Diameter: 54mm - Rose Projection: 10mm - Concealed Fix.',
        itemUrl: 'https://ironmongeryexperts.co.uk/set-rose-matte-black-bathroom-turn-emergency-release-a9010bl.html',
        image: require('./bathdoorturn.jpg'),
      },
      {
        name: 'WC/Shower/Bathroom - Hinges',
        title: 'Pair Powder Coat Black 102mm (4") Ball Bearing Steel Hinge | ZHS43PCB',
        description: 'Powder coated black - BS EN 1634-1 - Fire Rated 30/60 - Manufactured by Zoo Hardware.',
        itemUrl: 'https://ironmongeryexperts.co.uk/pair-powder-coat-black-102mm-4-ball-bearing-steel-hinge-zhs43pcb.html',
        image: require('./doorhinge.jpg'),
      },
    ]
  }
}

