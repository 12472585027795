import React from "react"
import LayoutApp from "./layout-app"
import DownloadableListItem from "./downloadable-list-item"
import brochure from "./../static/brochure.pdf"

const PortalBrochure = ({ data }) => {
  return (
    <LayoutApp title={"Brochure"}>
      <p className="mt-2 text-base leading-5 text-gray-600">
        View or download the Bright Heights sales brochure.
      </p>

      <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
        <div className="px-4 py-5 sm:p-6">
          <DownloadableListItem
            siteUrl={data.site.siteMetadata.siteUrl}
            name='Bright Heights Brochure'
            path={brochure}
          />
        </div>
      </div>
    </LayoutApp>
  )
}

export default PortalBrochure
