import React from "react"
import { isLoggedIn } from "../services/auth"
import { navigate } from "@reach/router"

// Wrap the require in check for window
// const navigate = typeof window !== `undefined` ? require("gatsby").navigate : null

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/portal/login`) {
    navigate("/portal/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
