import React from "react"

const SpecListItem = ({ item: { name, description, image, itemUrl, title }}) => (
  <li>
    <a href={itemUrl} className="block hover:bg-gray-100" target='_blank'>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="flex-shrink-0">
            {image && <img className="w-24 rounded" src={image} alt={name} />}
            {!image && <div className='rounded w-24 bg-gray-500' />}
          </div>
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="font-medium text-gray-900">{name}</p>
              <p className="mt-2 text-sm text-gray-700">
                {title}
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="text-sm text-gray-700">
                  {description}
                </p>
                <p className="mt-2 text-sm text-gray-500 hover:text-gray-700 truncate">
                  {itemUrl}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
               fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"/>
          </svg>
        </div>
      </div>
    </a>
  </li>
)

export default SpecListItem
