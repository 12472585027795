import React, { Component } from "react"
import emailjs from "emailjs-com"
import Alert from "./alert"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.botInput = React.createRef()
    this.state = {
      name: "",
      flat: "",
      email: "",
      phone: "",
      message: "",
      touched: {},
      errors: {},
      result: null,
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    const { name, flat, email, phone, message, errors, touched } = this.state

    if (this.botInput.current.value.length) {
      return
    }

    if (!(touched.name && touched.email && touched.message)) {
      this.setState({
        touched: {
          name: true,
          flat: true,
          email: true,
          message: true,
        },
      })
      this.validate()
      return
    }

    if (Object.keys(errors).length > 0) {
      return
    }

    emailjs
      .send(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        { name, flat, email, phone, message, siteName: this.props.siteName },
        process.env.GATSBY_EMAILJS_USER_ID
      )
      .then(
        data => {
          this.setState({result: data})
          console.log(data)
        },
        error => {
          this.setState({result: error})
          console.log(error)
        }
      )
  }

  handleInputChange(event) {
    const { name, value } = event.target

    this.setState(
      {
        [name]: value,
      },
      this.validate
    )
  }

  handleInputBlur(event) {
    this.setState({
      touched: { ...this.state.touched, ...{ [event.target.name]: true } },
    })
  }

  validate() {
    const errors = {}

    const { name, flat, email, message } = this.state

    if (!name.trim()) {
      errors.name = "Please enter a name."
    }
    
    if (!message.trim()) {
      errors.flat = "Please enter a flat number."
    }

    const re = /\S+@\S+\.\S+/
    if (!email.trim()) {
      errors.email = "Please enter an email address."
    } else if (!re.test(email)) {
      errors.email = "Please enter a valid email address."
    }

    if (!message.trim()) {
      errors.message = "Please enter a message."
    }

    this.setState({ errors })
  }

  render() {
    return (
      <>
        {this.state.result && this.state.result.status !== 200 && (
        <div className='mt-8'>
          <Alert
            title="Oh dear..."
            state="error"
            content={`There has been an error submitting your form. Please send an email directly to info.prestonroadapartments@gmail.com.`}
          />
        </div>
        )}

        {this.state.result && this.state.result.status === 200 && (
          <div className={'mt-8'}>
            <Alert
              title="Thanks for getting in touch!"
              state="success"
              content={`One of the ${this.props.siteName} team will get back to you shortly.`}
            />
          </div>

        )}

        {!this.state.result ? (
          <form
            className="mt-8"
            onSubmit={event => this.handleSubmit(event)}
          >
            <input style={{ display: "none" }} ref={this.botInput} />
            <div className="mt-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Name <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="name"
                  name="name"
                  className={`form-input py-2 px-4 block w-full transition ease-in-out duration-150 ${
                    this.state.touched.name && this.state.errors.name
                      ? "border border-red-500"
                      : null
                  }`}
                  onChange={event => this.handleInputChange(event)}
                  onBlur={event => this.handleInputBlur(event)}
                  value={this.state.name}
                  required
                />
              </div>
              {this.state.touched.name && this.state.errors.name ? (
                <p className="text-red-500 text-xs italic mt-2">
                  {this.state.errors.name}
                </p>
              ) : null}
            </div>
            <div className="mt-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email"
                  type="email"
                  name="email"
                  className={`form-input py-2 px-4 block w-full transition ease-in-out duration-150 ${
                    this.state.touched.email && this.state.errors.email
                      ? "border border-red-500"
                      : null
                  }`}
                  onBlur={event => this.handleInputBlur(event)}
                  onChange={event => this.handleInputChange(event)}
                  value={this.state.email}
                  required
                />
              </div>
              {this.state.touched.email && this.state.errors.email ? (
                <p className="text-red-500 text-xs italic mt-2">
                  {this.state.errors.email}
                </p>
              ) : null}
            </div>
            <div className="mt-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="phone"
                  name="phone"
                  type="phone"
                  className="form-input py-2 px-4 block w-full transition ease-in-out duration-150"
                  onChange={event => this.handleInputChange(event)}
                  onBlur={event => this.handleInputBlur(event)}
                  value={this.state.phone}
                />
              </div>
            </div>
            <div className="mt-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Message <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className={`form-textarea py-2 px-4 block w-full transition ease-in-out duration-150 ${
                    this.state.touched.message && this.state.errors.message
                      ? "border border-red-500"
                      : null
                  }`}
                  onChange={event => this.handleInputChange(event)}
                  onBlur={event => this.handleInputBlur(event)}
                  value={this.state.message}
                  required
                />
              </div>
              {this.state.touched.message && this.state.errors.message ? (
                <p className="text-red-500 text-xs italic mt-2">
                  {this.state.errors.message}
                </p>
              ) : null}
            </div>
            <div className="mt-4">
              <span className="w-full inline-flex rounded-md shadow-sm">
                <button
                  className={`w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus:bg-primary-400 transition duration-150 ease-in-out`}
                >
                  Send
                </button>
              </span>
            </div>
          </form>
        ) : null}
      </>
    )
  }
}
