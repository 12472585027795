import flat1 from "./flat1.pdf"
import flat2 from "./flat2.pdf"
import flat3 from "./flat3.pdf"
import flat4 from "./flat4.pdf"
import flat5 from "./flat5.pdf"
import flat6 from "./flat6.pdf"
import flat7 from "./flat7.pdf"
import flat8 from "./flat8.pdf"
import flat9 from "./flat9.pdf"
import flat10 from "./flat10.pdf"
import flat11 from "./flat11.pdf"
import flat12 from "./flat12.pdf"
import flat13 from "./flat13.pdf"
import flat14 from "./flat14.pdf"
import flat15 from "./flat15.pdf"
import flat16 from "./flat16.pdf"
import flat17 from "./flat17.pdf"
import flat18 from "./flat18.pdf"
import flat19 from "./flat19.pdf"
import flat20 from "./flat20.pdf"
import flat21 from "./flat21.pdf"
import flat22 from "./flat22.pdf"
import flat23 from "./flat23.pdf"
import flat24 from "./flat24.pdf"
import flat25 from "./flat25.pdf"

export const plans = {
  flat1: {
    label: 'Flat 1',
    name: 'Flat 1 Lease Plan',
    file: flat1,
  },
  flat2: {
    label: 'Flat 2',
    name: 'Flat 2 Lease Plan',
    file: flat2,
  },
  flat3: {
    label: 'Flat 3',
    name: 'Flat 3 Lease Plan',
    file: flat3,
  },
  flat4: {
    label: 'Flat 4',
    name: 'Flat 4 Lease Plan',
    file: flat4,
  },
  flat5: {
    label: 'Flat 5',
    name: 'Flat 5 Lease Plan',
    file: flat5,
  },
  flat6: {
    label: 'Flat 6',
    name: 'Flat 6 Lease Plan',
    file: flat6,
  },
  flat7: {
    label: 'Flat 7',
    name: 'Flat 7 Lease Plan',
    file: flat7,
  },
  flat8: {
    label: 'Flat 8',
    name: 'Flat 8 Lease Plan',
    file: flat8,
  },
  flat9: {
    label: 'Flat 9',
    name: 'Flat 9 Lease Plan',
    file: flat9,
  },
  flat10: {
    label: 'Flat 10',
    name: 'Flat 10 Lease Plan',
    file: flat10,
  },
  flat11: {
    label: 'Flat 11',
    name: 'Flat 11 Lease Plan',
    file: flat11,
  },
  flat12: {
    label: 'Flat 12',
    name: 'Flat 12 Lease Plan',
    file: flat12,
  },
  flat13: {
    label: 'Flat 13',
    name: 'Flat 13 Lease Plan',
    file: flat13,
  },
  flat14: {
    label: 'Flat 14',
    name: 'Flat 14 Lease Plan',
    file: flat14,
  },
  flat15: {
    label: 'Flat 15',
    name: 'Flat 15 Lease Plan',
    file: flat15,
  },
  flat16: {
    label: 'Flat 16',
    name: 'Flat 16 Lease Plan',
    file: flat16,
  },
  flat17: {
    label: 'Flat 17',
    name: 'Flat 17 Lease Plan',
    file: flat17,
  },
  flat18: {
    label: 'Flat 18',
    name: 'Flat 18 Lease Plan',
    file: flat18,
  },
  flat19: {
    label: 'Flat 19',
    name: 'Flat 19 Lease Plan',
    file: flat19,
  },
  flat20: {
    label: 'Flat 20',
    name: 'Flat 20 Lease Plan',
    file: flat20,
  },
  flat21: {
    label: 'Flat 21',
    name: 'Flat 21 Lease Plan',
    file: flat21,
  },
  flat22: {
    label: 'Flat 22',
    name: 'Flat 22 Lease Plan',
    file: flat22,
  },
  flat23: {
    label: 'Flat 23',
    name: 'Flat 23 Lease Plan',
    file: flat23,
  },
  flat24: {
    label: 'Flat 24',
    name: 'Flat 24 Lease Plan',
    file: flat24,
  },
  flat25: {
    label: 'Flat 25',
    name: 'Flat 25 Lease Plan',
    file: flat25,
  },
};
