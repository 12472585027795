import React from "react"
import LayoutApp from "./layout-app"
import dapatchiLogo from "../images/dapatchi-logo.svg"

const PortalDeveloper = ({ data }) => {

  return (
      <LayoutApp title={"The Developer"}>
          <p className="mt-2 text-base leading-5 text-gray-600">
              Your new Bright Heights home was painstakingly and meticulously delivered by the highly skilled team at the <a
              className="text-gray-900 hover:text-gray-700" href={'http://dapatchi.com'}>Dapatchi Group</a>.
          </p>

          <div className="bg-white overflow-hidden shadow rounded-lg rounded-b-none mt-8">
              <div className="px-4 py-5 sm:p-6">
                  <img
                      src={dapatchiLogo}
                      className="w-full md:w-1/2 mx-auto mt-8"
                      alt="Dapatchi Group"
                  />

                  <p className="mt-4 text-base leading-5 text-gray-700 mt-8">
                      The Dapatchi Group was founded to harness the knowledge, experience and talent of a dynamic group of property entrepreneurs who focus on finding solutions where others see problems. Dapatchi is built on a foundation of always adding value and creating win-win solutions.
                  </p>
                  <p className="mt-4 text-base leading-5 text-gray-700">
                      The vision of the Dapatchi Directors is to deliver total property solutions with an in-house team, setting the highest standards and maintaining full accountability of the results. The mission is to challenge accepted working practice, innovate and redefine better working methods within the property space.
                  </p>

                  <p className="mt-4 text-base leading-5 text-gray-700">
                      Dapatchi offer development, management, consultancy, contractor and design services. Delivering excellence across multiple sectors with a specialist skill set in commercial to residential redevelopments.
                  </p>

                  <p className="mt-4 text-base leading-5 text-gray-700">
                      Dapatchi believe in people and as such, they strive to be excellent employers where people feel safe, appreciated, valued and informed. Dapatchi Group has a team of around 150 staff as well as a wide-reaching network of partners and property professionals.
                  </p>

                  <p className="mt-4 text-base leading-5 text-gray-700">
                      The Dapatchi ethos and values prioritise the needs of clients while our commitment to delivering results ensures that we exceed expectations. This is reflected in the finish of you're beautiful new Bright Heights home.
                  </p>

                  <p className="mt-4 text-base leading-5 text-gray-700">
                      For more information about The Dapatchi Group and the services that they offer, please <a
                      href="http://dapatchi.com" className={'hover:text-gray-700 underline'}>visit the website</a>, email <a
                      href="mailto:info@dapatchi.com" className={'hover:text-gray-700 underline'}>info@dapatchi.com</a> or give them a call on <a href={'tel:01302 965585'} className={'hover:text-gray-700 underline'}> 01302 965585.</a>
                  </p>
              </div>
          </div>
      </LayoutApp>
  )
}

export default PortalDeveloper
