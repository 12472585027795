export const portalNavLinks = [
  {
    path: "/portal/appliances",
    title: "Appliances",
  },
  {
    path: "/portal/specification",
    title: "Spec",
  },
  {
    path: "/portal/palette",
    title: "Palette",
  },
  {
    path: "/portal/brochure",
    title: "Brochure",
  },
  {
    path: "/portal/plans",
    title: "Plans",
  },
  {
    path: "/portal/gallery",
    title: "Gallery",
  },
  {
    path: "/portal/developer",
    title: "Developer",
  },
  {
    path: "/portal/location",
    title: "Location",
  },
  {
    path: "/portal/contact",
    title: "Contact",
  },
]
