import React, { Component } from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import logo from "../images/logo.png"
import LayoutFull from "./layout-full"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      errors: null,
    }
  }

  handleUpdate(event) {
    if (this.state.errors && this.state.errors.password) {
      this.setState({ errors: null })
    }

    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    if (!handleLogin(this.state)) {
      this.setState({
        password: ``,
        errors: {
          password: "Incorrect password. Hint: {Location}{Year}",
        },
      })
    } else {
      navigate(`/portal`)
    }
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/portal`)
    }

    return (
      <LayoutFull>
        <img
          className="mx-auto w-full sm:w-3/4 h-auto"
          src={logo}
          alt={`${this.props.data.site.siteMetadata.title} Logo`}
        />

        <h2 className="mt-12 text-center text-4xl leading-9 font-extrabold text-gray-900">
          Log In
        </h2>

        <p className="mt-2 text-center text-base leading-5 text-gray-600">
          Log in to access the homeowner portal
        </p>

        <form
          className="mt-8"
          method="post"
          onSubmit={event => this.handleSubmit(event)}
        >
          <div className="rounded-md shadow-sm">
            <input
              aria-label="Password"
              name="password"
              type="password"
              required
              className="form-input text-base py-2 px-4 block w-full transition ease-in-out duration-150"
              placeholder="Password"
              onChange={event => this.handleUpdate(event)}
              value={this.state.password}
            />
          </div>

          {this.state.errors && this.state.errors.password && (
            <p className="text-red-500 text-xs italic mt-2">
              {this.state.errors.password}
            </p>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus:bg-primary-400 transition duration-150 ease-in-out"
            >
              Log in
            </button>
          </div>
        </form>
      </LayoutFull>
    )
  }
}

export default Login
