import React from "react"
import LayoutApp from "./layout-app"
import { colors } from './../colors/index'

const PortalColors = ({ data }) => {
  return (
    <LayoutApp title={"Palette"}>
      <p className="mt-2 text-base leading-5 text-gray-600">
        The Bright Heights color palette was carefully selected to compliment your spacious, modern new home.
      </p>

      <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
        <div className="px-4 py-5 sm:p-6">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {colors.map((color, index) => (
              <div key={index}>
                <div className={'flex justify-center'}>
                  <div className={'shadow-lg rounded-lg w-32 h-32 md:w-40 md:h-40'} style={{'background': `${color.hex}`}} />
                </div>
                <p className="mt-2 text-xl text-center font-bold leading-tight text-gray-900">
                  { color.where }
                </p>
                <p className={'mt-1 text-center leading-tight font-semibold text-gray-900'}>
                  { color.name }
                </p>
                <p className={'mt-2 text-center leading-tight text-gray-700'}>
                  { color.brand }
                </p>
                <p className={'mt-2 text-center leading-tight text-sm text-gray-500 truncate'}>
                  <a href={color.url} target="_blank" className={'hover:text-gray-700'}>{ color.url }</a>
                </p>
              </div>
              )
            )}
          </div>
        </div>
      </div>
    </LayoutApp>
  )
}

export default PortalColors
