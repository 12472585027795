import React from "react"

const DownloadableListItem = ({name, path, siteUrl}) => {
  const downloadUrl = `${siteUrl}${path}`
  return (
    <div className="py-4 sm:py-8 flex justify-between items-center">

      <h2 className="texl-xl sm:text-2xl font-bold leading-tight text-gray-900">
        <a href={downloadUrl} target='_blank' rel='noreferrer' className='hover:text-gray-700'>
          {name}
        </a>
      </h2>

      <div>
        <a
          href={downloadUrl}
          download
          className="relative py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
        >
          Download
        </a>
      </div>

    </div>
  )
}

export default DownloadableListItem
