import React from "react"

export default function Alert({ title, content, state }) {
  const stateColors = {
    success: {
      bgClassName: `bg-green-100`,
      titleClassName: `text-green-800`,
      textClassName: `text-green-700`,
      borderClassName: `border-green-800`
    },
    error: {
      bgClassName: `bg-red-100`,
      titleClassName: `text-red-800`,
      textClassName: `text-red-700`,
      borderClassName: `border-red-800`
    },
    info: {
      bgClassName: `bg-yellow-100`,
      titleClassName: `text-yellow-800`,
      textClassName: `text-yellow-700`,
      borderClassName: `border-yellow-800`
    },
  }

  return (
    <div className={`rounded-md ${stateColors[state].bgClassName} border ${stateColors[state].borderClassName} p-4 shadow-sm`}>
      <div className="flex">
        <div>
          <h3
            className={`text-sm leading-5 font-medium ${stateColors[state].titleClassName}`}
          >
            {title}
          </h3>
          {content && (
            <div
              className={`mt-2 text-sm leading-5 ${stateColors[state].textClassName}`}
            >
              <p>{content}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
