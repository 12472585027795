import React, { useState } from "react"
import LayoutApp from "./layout-app"
import { spec } from "../specification"
import SpecListItem from "./spec-list-item"

const PortalSpecification = ({ data }) => {
  const specKeys = Object.keys(spec)
  const [activeSpecKey, setActiveSpecKey] = useState(specKeys[0])

  const handleTabChange = key => {
    setActiveSpecKey(key)
  }

  return (
    <LayoutApp title={"Specification & Fittings"}>
      <p className="mt-2 text-base leading-5 text-gray-600">
        If you need any spares or additional fixtures, you can find the specification and supplier details below.
      </p>

      <div className="bg-white overflow-hidden shadow rounded-lg rounded-b-none mt-8">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:hidden">
            <select
              aria-label="Selected tab"
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
              value={activeSpecKey}
              onChange={event => handleTabChange(event.target.value)}
            >
              {specKeys.map(key => (
                <option value={key} key={key}>
                  {spec[key].title}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">
                {specKeys.map((key, index) => (
                  <a
                    key={key}
                    onClick={() => handleTabChange(key)}
                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer ${
                      index > 0 ? " ml-8 " : ""
                    }
                    ${
                      key === activeSpecKey
                        ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                        : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                    }`}
                  >
                    {spec[key].title}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-t-none">
        <div className="divide-y divide-gray-200">
          <ul className={'divide-y divide-gray-200'}>
            {spec[activeSpecKey].items.map((item, index) => <SpecListItem item={item} key={index} /> )}
          </ul>
        </div>
      </div>
    </LayoutApp>
  )
}

export default PortalSpecification
