import React, { useState } from "react"
import LayoutApp from "./layout-app"
import { appliances } from "../appliances/index"
import DownloadableListItem from "./downloadable-list-item"

const PortalAppliances = ({ data }) => {
  const applianceKeys = Object.keys(appliances)
  const [activeApplianceKey, setActiveApplianceKey] = useState(applianceKeys[0])

  const handleTabChange = key => {
    setActiveApplianceKey(key)
  }

  return (
    <LayoutApp title={"Appliances"}>
      <p className="mt-2 text-base leading-5 text-gray-600">
        Select an appliance to view or download the full operating manual.
      </p>

      <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:hidden">
            <select
              aria-label="Selected tab"
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
              value={activeApplianceKey}
              onChange={event => handleTabChange(event.target.value)}
            >
              {applianceKeys.map(key => (
                <option value={key} key={key}>
                  {appliances[key].title}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex justify-between">
                {applianceKeys.map((key, index) => (
                  <a
                    key={key}
                    onClick={() => handleTabChange(key)}
                    className={`whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none cursor-pointer
                    ${
                      key === activeApplianceKey
                        ? " border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700"
                        : " border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300"
                    }`}
                  >
                    <span className={'hidden lg:flex'}>{appliances[key].title}</span>
                    <span className={'lg:hidden'}>{appliances[key].shortTitle ? appliances[key].shortTitle : appliances[key].title}</span>
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {appliances[activeApplianceKey].headerSheet.landscape && (
              <img src={appliances[activeApplianceKey].headerSheet.landscape} className={"mt-4 w-full hidden sm:flex"} alt={`${appliances[activeApplianceKey].name} Quick Start Guide`} />
          )}

          {appliances[activeApplianceKey].headerSheet.portrait && (
              <img src={appliances[activeApplianceKey].headerSheet.portrait} className={"mt-4 w-full sm:hidden"} alt={`${appliances[activeApplianceKey].name} Quick Start Guide`} />
          )}

          <div className={"mt-4 w-full"}>
            <div className="divide-y divide-gray-200">
              {appliances[activeApplianceKey].items.map((appliance, index) => (
                <DownloadableListItem
                  key={index}
                  siteUrl={data.site.siteMetadata.siteUrl}
                  name={appliance.name}
                  path={appliance.manual}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutApp>
  )
}

export default PortalAppliances
