import React from "react"
import LayoutApp from "./layout-app"

const PortalLocation = ({ data }) => (
  <LayoutApp title={"Location"}>
    <p className="mt-2 text-base leading-5 text-gray-600">
      Situated in central Brighton with the city centre & Brighton Mainline Railway Station a stroll away, Bright Heights is in the heart of this thriving hub, with an array of boutiques, cafés, bars, bistros, shops, schools, parks and sports facilities, giving you plenty to explore. Minutes away from Bright Heights is one of Brighton’s oldest and largest parks spanning an impressive 63 acres. The park offers the perfect respite to city living. Whether you want to enjoy a picnic on a summer’s day or want to be active and take part in sport, this wonderful urban park has it all.
    </p>
    <iframe className={'border-none w-full mt-8 rounded-lg shadow'}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.773836735766!2d-0.14409254816189587!3d50.835352967335616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48758579de882db5%3A0xfb111ba747f609c2!2s87%20Preston%20Rd%2C%20Brighton!5e0!3m2!1sen!2suk!4v1607423358144!5m2!1sen!2suk"
      height="450"  />
  </LayoutApp>
)

export default PortalLocation
