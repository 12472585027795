import React from "react"
import LayoutApp from "./layout-app"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const PortalHome = ({ data }) => {
const { image: riverdaleImage } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "brightheights.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <LayoutApp title={"Home"}>
        <p className="mt-2 text-base leading-5 text-gray-600">
          Welcome to your new home, and welcome to the{" "}
          {data.site.siteMetadata.title} homeowner portal.
        </p>
        <p className="mt-2 text-base leading-5 text-gray-600">
          This portal acts as a hub for all the information relating to your home. Here, you can browse the development brochure, lease plans, specification (including links to suppliers), all the info regarding the safety,
          maintenance and cleaning of your new appliances, along with a convenient
          way to contact the {data.site.siteMetadata.title} team should you have any
          further questions or issues.
        </p>
        <Img
          className="w-full h-auto rounded-lg mt-8 shadow"
          alt="Riverdale Building"
          fluid={riverdaleImage.childImageSharp.fluid}
        />
      </LayoutApp>
  )
}

export default PortalHome
