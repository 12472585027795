import React, { useState } from "react"
import LayoutApp from "./layout-app"
import { plans } from '../plans'
import DownloadableListItem from "./downloadable-list-item"
import estate from "../plans/estate.pdf"


const PortalPlans = ({ data }) => {
  const planKeys = Object.keys(plans)
  const [activePlanKey, setActivePlanKey] = useState(null)

  const handleTabChange = key => {
    setActivePlanKey(key)
  }

  return (
    <LayoutApp title={"Plans"}>
      <p className="mt-2 text-base leading-5 text-gray-600">
        View or download your Bright Heights lease plans.
      </p>
      <div className="bg-white overflow-hidden shadow rounded-lg mt-8">
        <div className="px-4 py-5 sm:p-6">
          <select
            id={'flat'}
            onChange={event => handleTabChange(event.target.value)}
            className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          >
            <option value={''}>Select your flat...</option>
            { planKeys.map((key, index) => <option value={key} key={key}>{plans[key].label}</option> )}
          </select>
            <div className="divide-y divide-gray-200">
              <DownloadableListItem
                siteUrl={data.site.siteMetadata.siteUrl}
                name='Estate Layout Plan'
                path={estate}
              />

              {activePlanKey && <DownloadableListItem
                siteUrl={data.site.siteMetadata.siteUrl}
                name={plans[activePlanKey].name}
                path={plans[activePlanKey].file}
              />}
          </div>
        </div>
      </div>
    </LayoutApp>
  )
}

export default PortalPlans
