import dishwasherManual from "./manuals/dishwasher.pdf"
import intercomManual from "./manuals/intercom.pdf"
import ufManual from "./manuals/underfloor-heating.pdf"
import ovenManual from "./manuals/oven.pdf"
import hobManual from './manuals/hob.pdf'
import fridgeManual from './manuals/fridge.pdf'
import lightingManual from './manuals/lighting.pdf'
import hifiManual from './manuals/hifi.pdf'
import mvhrManual from './manuals/mvhr.pdf'
import hifiHeader from './headers/hifi.png'
import hifiHeaderPortrait from './headers/hifi-portrait.png'
import dishwasherHeader from './headers/dishwasher.png'
import dishwasherHeaderPortrait from './headers/dishwasher-portrait.png'
import doorHeader from './headers/door.png'
import doorHeaderPortrait from './headers/door-portrait.png'
import hobHeader from './headers/hob.png'
import hobHeaderPortrait from './headers/hob-portrait.png'
import ovenHeader from './headers/oven.png'
import ovenHeaderPortrait from './headers/oven-portrait.png'
import ct2manual from './manuals/Rointe CT2 Controller.pdf'
import rointealexa from './manuals/Rointe Alexa Manual.pdf'
import rointeconnect from './manuals/Rointe Connect App Manual.pdf'
import TelfordInstallManual from "./manuals/Telford Cylinder Installation Guide.pdf"
import TelfordTempestGuide from "./manuals/Telford Tempest + Tornado Guide.pdf"

export const appliances = {
  dishwasher: {
    title: "Dishwasher",
    headerSheet: {
      landscape: dishwasherHeader,
      portrait: dishwasherHeaderPortrait,
    },
    items: [
      {
        manual: dishwasherManual,
        name: "AEG FSB42607Z Fully Integrated Dishwasher",
      },
    ],
  },
  intercom: {
    title: "Intercom",
    headerSheet: {
      landscape: doorHeader,
      portrait: doorHeaderPortrait,
    },
    items: [
      {
        manual: intercomManual,
        name: "Bell System Tabellet Colour Monitor Intercom",
      },
    ],
  },

  hob: {
    title: "Hob",
    headerSheet: {
      landscape: hobHeader,
      portrait: hobHeaderPortrait,
    },
    items: [
      {
        manual: hobManual,
        name: "Bosch PUE611BF1B 4 Zone Induction Hob",
      },
    ],
  },

  heating: {
    title: "Underfloor Heating",
    shortTitle: "Heating",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
      {
        manual: ufManual,
        name: "Rointe MSIWFH030 Erko Foil underfloor heating",
      },
    ],
  },
  oven: {
    title: 'Oven',
    headerSheet: {
      landscape: ovenHeader,
      portrait: ovenHeaderPortrait,
    },
    items: [
      {
        manual: ovenManual,
        name: 'Bosch HBS534BS0B Series 4 Single Oven',
      }
    ]
  },
  fridge: {
    title: 'Fridge Freezer',
    shortTitle: "Fridge",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
      {
        manual: fridgeManual,
        name: 'Zanussi ZBB28441SV Integrated Fridge Freezer',
      }
    ]
  },
  lighting: {
    title: 'Lighting System',
    shortTitle: "Lighting",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
      {
        manual: lightingManual,
        name: 'KSR KSRFRD381 Lighting System',
      }
    ]
  },
  hifi: {
    title: 'HiFi',
    headerSheet: {
      landscape: hifiHeader,
      portrait: hifiHeaderPortrait,
    },
    items: [
      {
        manual: hifiManual,
        name: 'Systemline E100 HiFi Sound System',
      }
    ]
  },
  mvhr: {
    title: 'Heat Recovery',
    shortTitle: "MVHR",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
      {
        manual: mvhrManual,
        name: 'Monsoon MON-HRH/230-100 Heat Recovery Unit',
      },
    ]
  },
    rointe: {
    title: 'Rointe',
    shortTitle: "Rointe",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
 {
        manual: ct2manual,
        name: 'Rointe CT2 Controller',
      },
      {
        manual: rointealexa,
        name: 'Rointe Alexa',
      },
      {
        manual: rointeconnect,
        name: 'Rointe Connect',
      },
    ]
  },
  telford: {
    title: 'Telford Boiler',
    shortTitle: "Boiler",
    headerSheet: {
      landscape: null,
      portrait: null,
    },
    items: [
      {
        manual: TelfordInstallManual,
        name: 'Installation Guide & Customers Copy',
      },
	{
        manual: TelfordTempestGuide,
        name: 'Tempest Guide',
      },
    ]
  },
}
